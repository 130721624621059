import React, { useContext, useEffect } from "react"
import { useRouter } from "next/router"
import { last, split } from "ramda"
import { TrackingLabelContext } from "../../lib/tracking-label-context"

const customPageNames = ["africa", "brazil"]

const TalentBerlinTracking: React.FC = () => {
  const router = useRouter()

  const resource = last(split("/", router.asPath)) || ""
  const pageName = customPageNames.includes(resource) ? resource : null

  const { setTrackingLabel } = useContext(TrackingLabelContext)

  useEffect(() => {
    setTrackingLabel(pageName)
  }, [pageName, setTrackingLabel])

  return <></>
}

export default TalentBerlinTracking
